import React from 'react';
import Secure from './base_templates/Secure';
import Sms from './base_templates/Sms';
import Email from './base_templates/Email';

const Base = ({ mode, type, locale, handleChangeLocale, children, metadata }) =>
  type?.toLowerCase() === 'secure' ? (
    <Secure
      mode={mode}
      locale={locale}
      handleChangeLocale={handleChangeLocale}
      metadata={metadata}
    >
      {children}
    </Secure>
  ) : type?.toLowerCase() === 'email' ? (
    <Email
      mode={mode}
      locale={locale}
      handleChangeLocale={handleChangeLocale}
      metadata={metadata}
    >
      {children}
    </Email>
  ) : type?.toLowerCase() === 'sms' ? (
    <Sms
      mode={mode}
      locale={locale}
      handleChangeLocale={handleChangeLocale}
      metadata={metadata}
    >
      {children}
    </Sms>
  ) : (
    <div>{children}</div>
  );

export default Base;
