import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import { textField } from '../../../../components/inputs';
import { noop, validations } from '../../../../helperFunctions';
import styles from './manage_communication_preferences.module.scss';
import {
  getNotificationPreferences,
  updateNotificationPreferences,
} from './apis';
import TextField from '../../../components/inputs/TextField';
import { LanguageTranslator } from '../../../../LanguageTranslator';
import { CheckBox } from '../../../components/inputs';
import ConsentBanner from '../../../../components/shared/ConsentBanner';
import AppTooltip from '../../../../components/shared/AppTooltip';

const CommunicationPreferenceForm = ({
  patientID,
  isPatientFacing = false,
  setLoader = noop,
  setLocale = noop,
  syncFormikToRedux = noop,
  locale,
}) => {
  const [fields, setFields] = useState({});
  const [metadata, setMetadata] = useState({});
  const [selfServeLinkViaEmail, setSelfServeLinkViaEmail] = useState(false);
  const [selfServeLinkViaText, setSelfServeLinkViaText] = useState(false);

  const [consentGiven, setConsentGiven] = useState(false);

  const handleFormikChange = (values, isValid) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      syncFormikToRedux(values, isValid); // Mark the form as dirty in Redux
    }, [isValid, values]);
  };

  const loadPreferences = (showLoader = true) => {
    if (showLoader) {
      setLoader(true);
    }
    getNotificationPreferences(patientID).then((r) => {
      setLoader(false);
      if (r && r.data) {
        setFields(r.data.fields);
        setMetadata(r.data.metadata);
        setLocale(r.data.metadata.locale);
      }
    });
  };

  const sendNotificationPreferencesSelfServiceLink = () => {};

  const handleSubmit = (values, { setSubmitting }) => {
    setLoader(true);
    setSubmitting(true);
    const formData = {
      ...values,
      'patient_modal_via-email': values.via_email,
      'patient_modal_via-text': values.via_text,
      'appt_confirm_via-email': values.appt_via_email,
      'appt_confirm_via-text': values.appt_via_text,
      'assessment_link_via-email': values.at_home_via_email,
      'assessment_link_via-text': values.at_home_via_text,
      'appt_reminder_via-email': values.appt_reminder_via_email,
      'appt_reminder_via-text': values.appt_reminder_via_text,
      'notification-phone': values.notification_text,
      'notification-email': values.notification_email,
      patient_id: patientID,
    };
    updateNotificationPreferences(formData).then(() => {
      setSubmitting(false);
      loadPreferences(false);
    });
  };

  useEffect(() => {
    loadPreferences();
  }, [patientID]);

  const initialValues = useMemo(() => {
    const values = {
      via_email: false,
      notification_email: '',
      via_text: false,
      notification_text: '',
      appt_via_email: false,
      appt_via_text: false,
      appt_reminder_via_email: false,
      appt_reminder_via_text: false,
      at_home_via_email: false,
      at_home_via_text: false,
    };
    if (fields && fields.via_email) {
      Object.keys(values).forEach((key) => {
        const field = fields[key];
        values[key] =
          field.default ||
          field.value ||
          (field.type === 'switch' ? false : '');
      });
    }
    return values;
  }, [fields]);

  const validate = (values) => {
    const errors = {};
    const emailRequiredValidation = validations.required(
      values.notification_email,
      locale,
    );
    const emailFormatValidation = validations.email(
      values.notification_email,
      locale,
    );
    const phoneRequiredValidation = validations.required(
      values.notification_text,
      locale,
    );
    const phoneFormatValidation = validations.phone(
      values.notification_text,
      locale,
      'valid_phone_number_count',
    );

    if (values.via_email) {
      if (fields.notification_email.required) {
        if (emailRequiredValidation || emailFormatValidation) {
          errors.notification_email =
            emailRequiredValidation || emailFormatValidation;
        }
      } else if (emailFormatValidation) {
        errors.notification_email = emailFormatValidation;
      }
    }
    if (values.via_text) {
      if (fields.notification_text.required) {
        if (phoneRequiredValidation || phoneFormatValidation) {
          errors.notification_text =
            phoneRequiredValidation || phoneFormatValidation;
        }
      } else if (phoneFormatValidation) {
        errors.notification_text = phoneFormatValidation;
      }
    }
    return errors;
  };

  const translated = useMemo(() => {
    if (locale) {
      return LanguageTranslator.getTranslations(locale);
    }
    return {};
  }, [locale]);

  return fields ? (
    <Formik
      initialValues={initialValues}
      validate={validate}
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, dirty, values, setFieldValue, resetForm }) => {
        handleFormikChange(
          values,
          isValid && (isPatientFacing || !dirty || consentGiven),
        );
        const isOptedOutPhoneNumber = (phone_number) =>
          (metadata.text_opt_out || !metadata.notification_text_status) &&
          phone_number === initialValues.notification_text;

        return (
          <Form role="form" className={styles.comFormWrapper}>
            <div className={styles.comFormInner}>
              <div className={styles.comFormInner}>
                <div className={styles.comMethodsWrapper}>
                  <i className={styles.italicText}>
                    {isPatientFacing
                      ? translated.receive_notif_patient
                      : translated.receive_notif_provider}
                  </i>
                  <div className={styles.comMethodContainer}>
                    <div className={styles.comCheckboxContainer}>
                      <CheckBox
                        label={translated.email}
                        name="via_email"
                        disabled={patientID ? !metadata.edit_allowed : false}
                        onChange={(newValue) => {
                          if (newValue === false) {
                            [
                              'appt_via_email',
                              'appt_reminder_via_email',
                              'at_home_via_email',
                            ].forEach((f) => {
                              setFieldValue(f, false);
                            });
                            setSelfServeLinkViaEmail(false);
                          }
                        }}
                      />
                    </div>
                    <div className={styles.comFieldCont}>
                      <div className={styles.textFieldContainer}>
                        <TextField
                          label=""
                          name="notification_email"
                          placeholder={translated.enter_email_address}
                          component={textField}
                          mask="email"
                          fieldWrapClass={styles.w100}
                          className={clsx(styles.w100, 'input-f')}
                          errorClass={clsx('error', styles.fieldError)}
                          disabled={
                            !values.via_email ||
                            (patientID ? !metadata.edit_allowed : false)
                          }
                        />
                      </div>
                      {metadata &&
                        Object.keys(metadata).length > 0 &&
                        !metadata.notification_email_status && (
                          <p className={styles.errorText} id="emailStatus">
                            *{' '}
                            {isPatientFacing
                              ? translated.error_receiving_email_patient
                              : translated.error_receiving_email_provider}
                          </p>
                        )}
                    </div>
                  </div>
                  {metadata.allow_text_notification && (
                    <div className={styles.comMethodContainer}>
                      <div className={styles.comCheckboxContainer}>
                        <CheckBox
                          label={translated.text}
                          name="via_text"
                          disabled={
                            patientID
                              ? !metadata.edit_allowed ||
                                isOptedOutPhoneNumber(values.notification_text)
                              : false
                          }
                          onChange={(newValue) => {
                            if (newValue === false) {
                              [
                                'appt_via_text',
                                'appt_reminder_via_text',
                                'at_home_via_text',
                              ].forEach((f) => {
                                setFieldValue(f, false);
                              });
                              setSelfServeLinkViaText(false);
                            }
                          }}
                        />
                      </div>
                      <div className={styles.comFieldCont}>
                        <div className={styles.textFieldContainer}>
                          <TextField
                            label=""
                            name="notification_text"
                            placeholder={translated.enter_mobile_number}
                            mask="phone"
                            fieldWrapClass={styles.w100}
                            className={clsx(styles.w100, 'input-f')}
                            errorClass={clsx('error', styles.fieldError)}
                            disabled={
                              patientID ? !metadata.edit_allowed : false
                            }
                          />
                        </div>
                        {isOptedOutPhoneNumber(values.notification_text) && (
                          <p className="error margin-bottom-0">
                            *{' '}
                            {isPatientFacing
                              ? translated.sms_opted_out_or_invalid_patient?.replace(
                                  '%{contact_number}',
                                  metadata.twillio_number,
                                )
                              : translated.sms_opted_out_or_invalid_provider?.replace(
                                  '%{contact_number}',
                                  metadata.twillio_number,
                                )}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {!isPatientFacing && (
                  <div className="margin-top-xl">
                    <fieldset>
                      <i className="italic">
                        Would you like to send a Notification Preferences self
                        service link to this patient?
                      </i>
                      <div className="d-flex align-items-center space-between">
                        <div
                          className={clsx('d-flex', styles.checkboxContainer)}
                        >
                          <span className="checkbox-wrap">
                            <input
                              name="self_serve_link_via_email"
                              type="checkbox"
                              id="self_serve_link_via_email_checkbox"
                              disabled={
                                !values.via_email ||
                                (patientID ? !metadata.edit_allowed : false)
                              }
                              checked={selfServeLinkViaEmail}
                              onChange={(e) => {
                                setSelfServeLinkViaEmail(e.target.checked);
                              }}
                            />
                            <label
                              id="field-label-self_serve_link_via_email"
                              className="label-block"
                              htmlFor="self_serve_link_via_email_checkbox"
                              data-tip
                              data-for="field-tooltip__self_serve_link_via_email"
                              data-class="tooltip"
                            >
                              <span>Send via Email</span>
                            </label>
                            {!values.via_email && (
                              <AppTooltip
                                id="field-tooltip__self_serve_link_via_email"
                                place="top"
                                arrowColor="#222"
                                delayShow={400}
                              >
                                <div className={styles.tooltipContainer}>
                                  Please turn email ON in the above section and
                                  input the patient’s email where link is to be
                                  sent.
                                </div>
                              </AppTooltip>
                            )}
                          </span>
                          <span className="checkbox-wrap">
                            <input
                              name="self_serve_link_via_text"
                              type="checkbox"
                              id="self_serve_link_via_text_checkbox"
                              disabled={
                                !values.via_text ||
                                (patientID ? !metadata.edit_allowed : false)
                              }
                              checked={selfServeLinkViaText}
                              onChange={(e) => {
                                setSelfServeLinkViaText(e.target.checked);
                              }}
                            />
                            <label
                              id="field-label-self_serve_link_via_text"
                              className="label-block"
                              htmlFor="self_serve_link_via_text_checkbox"
                              data-tip
                              data-for="field-tooltip__self_serve_link_via_text"
                              data-class="tooltip"
                            >
                              <span>Send via Text</span>
                            </label>
                            {!values.via_text && (
                              <AppTooltip
                                id="field-tooltip__self_serve_link_via_text"
                                place="top"
                                arrowColor="#222"
                                delayShow={400}
                              >
                                <div className={styles.tooltipContainer}>
                                  Please turn text message ON in the above
                                  section and input the patient’s phone number
                                  where link is to be sent.
                                </div>
                              </AppTooltip>
                            )}
                          </span>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={sendNotificationPreferencesSelfServiceLink}
                          disabled={
                            !selfServeLinkViaEmail && !selfServeLinkViaText
                          }
                        >
                          Send Link
                        </button>
                      </div>
                    </fieldset>
                  </div>
                )}
                <div
                  className={clsx(
                    styles.comPreferenceContainer,
                    'margin-top-xl',
                  )}
                >
                  <i className={styles.italicText}>
                    {isPatientFacing
                      ? translated.notif_receive_patient
                      : translated.notif_receive_provider}
                  </i>
                  <div className={styles.comPreferencesWrapper}>
                    <div className={styles.comPreference}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={styles.labelBlack}>
                        {translated.appoint_confirm}
                      </label>
                      <div className={styles.inputAndMsgCont}>
                        {isPatientFacing && (
                          <p className={styles.parag}>
                            {translated.appoint_confirm_details}
                          </p>
                        )}
                        <div
                          className={clsx(
                            styles.inputCont,
                            styles.checkboxContainer,
                          )}
                        >
                          <CheckBox
                            label={translated.email}
                            name="appt_via_email"
                            disabled={
                              !values.via_email ||
                              (patientID ? !metadata.edit_allowed : false)
                            }
                          />
                          {metadata.allow_text_notification && (
                            <CheckBox
                              label={translated.text}
                              name="appt_via_text"
                              disabled={
                                !values.via_text ||
                                (patientID ? !metadata.edit_allowed : false)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <hr className={styles.separator} />
                    <div className={styles.comPreference}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={styles.labelBlack}>
                        {translated.appoint_reminder}
                      </label>
                      <div className={styles.inputAndMsgCont}>
                        {isPatientFacing && (
                          <p className={styles.parag}>
                            {translated.appoint_reminder_details}
                          </p>
                        )}
                        <div
                          className={clsx(
                            styles.inputCont,
                            styles.checkboxContainer,
                          )}
                        >
                          <CheckBox
                            label={translated.email}
                            name="appt_reminder_via_email"
                            disabled={
                              !values.via_email ||
                              (patientID ? !metadata.edit_allowed : false)
                            }
                          />
                          {metadata.allow_text_notification && (
                            <CheckBox
                              label={translated.text}
                              name="appt_reminder_via_text"
                              disabled={
                                !values.via_text ||
                                (patientID ? !metadata.edit_allowed : false)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <hr className={styles.separator} />
                    <div className={styles.comPreference}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={styles.labelBlack}>
                        {isPatientFacing
                          ? translated.send_assessment_link_patient
                          : translated.send_assessment_link_provider}
                      </label>
                      <div className={styles.inputAndMsgCont}>
                        {isPatientFacing && (
                          <p className={styles.parag}>
                            {translated.send_assessment_link_details}
                          </p>
                        )}
                        <div
                          className={clsx(
                            styles.inputCont,
                            styles.checkboxContainer,
                          )}
                        >
                          <CheckBox
                            label={translated.email}
                            name="at_home_via_email"
                            disabled={
                              !values.via_email ||
                              (patientID ? !metadata.edit_allowed : false)
                            }
                          />
                          {metadata.allow_text_notification && (
                            <CheckBox
                              label={translated.text}
                              name="at_home_via_text"
                              disabled={
                                !values.via_text ||
                                (patientID ? !metadata.edit_allowed : false)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!isPatientFacing && dirty && (
                  <div id={styles.messages}>
                    <div
                      className={clsx(
                        'margin-top-xl messages-wrap',
                        styles.consentContainer,
                      )}
                    >
                      <ConsentBanner
                        handleCancel={resetForm}
                        handleConsentChange={setConsentGiven}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {isPatientFacing && (
              <div className="margin-top-xl">
                <div
                  className={clsx('bold margin-bottom-lg', styles.disclaimer)}
                >
                  Your healthcare organization or provider may still send you
                  communications that do not come from Lucet.
                </div>
                <div className={clsx(styles.comModalFooter, 'modal-footer')}>
                  {(patientID ? metadata.edit_allowed : true) && (
                    <button
                      type="submit"
                      disabled={isSubmitting || !isValid || !dirty}
                      className={`btn btn-primary ${styles.comSubmitBtn} ${
                        !isValid ? 'inactive' : ''
                      }`}
                    >
                      {translated.save_changes}
                    </button>
                  )}
                </div>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  ) : null;
};

export default CommunicationPreferenceForm;
